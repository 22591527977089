import React from 'react';
import c from './images/6.jpg';

const Seccion2 = () => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Confidencialidad de la Información (Sección 2)</h2>
      <div className="space-y-4">
        <Info
          titulo="Ley General de Protección de Datos Personales de México"
          descripcion="Esta ley establece los principios y obligaciones que deben seguir las entidades públicas en México para proteger los datos personales de los ciudadanos. Su objetivo es garantizar que los datos de los individuos sean tratados con confidencialidad, seguridad y en apego a derechos fundamentales, protegiendo la privacidad y evitando abusos."
        />
        <Info
          titulo="Ley de Propiedad Industrial"
          descripcion="Protege los derechos relacionados con las invenciones, marcas, patentes y otros elementos industriales. Esta ley tiene como objetivo asegurar que las creaciones o productos industriales sean utilizados de manera legal y sin vulnerar los derechos del creador, lo que implica el manejo confidencial de información relacionada con tecnologías o productos."
        />
        <Info
          titulo="Ley Federal de Derechos de Autor"
          descripcion="Regula los derechos de los creadores sobre sus obras intelectuales, como libros, música, programas de software, y otros. Proteger la confidencialidad en este contexto significa asegurar que la obra de un autor no sea copiada, distribuida o utilizada sin su permiso."
        />
        <Info
          titulo="Ley Federal de Protección de Datos Personales en Posesión de los Particulares"
          descripcion="Esta ley se aplica a las entidades privadas en México y establece los principios y reglas para la protección y manejo de datos personales de los ciudadanos. Impone responsabilidades a empresas y organizaciones para tratar los datos de manera adecuada, garantizando la privacidad y el consentimiento informado del titular de los datos."
        />
        <Info
          titulo=" Código Penal Federal"
          descripcion="El Código Penal Federal establece sanciones penales para aquellos que violen la confidencialidad de información protegida, incluyendo acceso no autorizado, robo de información y otros delitos informáticos que involucren datos personales o información confidencial."
        />
        <Info
          titulo="Ley General de Transparencia y Acceso a la Información Pública"
          descripcion="Aunque se enfoca en garantizar el acceso a la información pública, esta ley también protege la confidencialidad de datos sensibles, como los relacionados con seguridad nacional, derechos humanos o información que pueda afectar la privacidad de individuos."
        />
        <Info
          titulo="Criptografía Simétrica"
          descripcion="La criptografía simétrica utiliza una única clave tanto para cifrar como para descifrar la información. Esto requiere que ambas partes (emisor y receptor) conozcan y mantengan en secreto la clave utilizada, lo que es crucial para asegurar la confidencialidad de los datos."
        />
        <Info
          titulo="Criptografía Asimétrica"
          descripcion="A diferencia de la simétrica, la criptografía asimétrica utiliza un par de claves: una pública y una privada. La clave pública cifra la información, mientras que solo la clave privada puede descifrarla, lo que añade una capa de seguridad en la comunicación y el intercambio de información confidencial."
        />
        <Info
          titulo="Cifrado por Bloques"
          descripcion="Divide los datos en bloques de un tamaño fijo y los cifra uno por uno."
        />
        <Info
          titulo="Cifrado por Flujo"
          descripcion="Cifra los datos de manera continua, bit por bit o byte por byte, lo que puede ser más adecuado para ciertos tipos de transmisión de datos en tiempo real."
        />
        <Info
          titulo="Criptoanálisis"
          descripcion="Es la técnica de descifrar información cifrada sin conocer la clave. El objetivo del criptoanálisis es encontrar vulnerabilidades en los sistemas de cifrado para comprometer la confidencialidad de los datos."
        />
      </div>
    </div>
  );
};

const Info = ({ titulo, descripcion }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">
      <img src={c} alt={titulo} className="rounded-lg" width={30} height={30} />
    </div>
    <div>
      <h3 className="text-lg font-bold">{titulo}</h3>
      <p className="text-gray-600">{descripcion}</p>
    </div>
  </div>
);


export default Seccion2;
