import React from 'react';
import c from './images/3.jpg';

const Seccion1 = () => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Ataques Cibernéticos (Sección 1)</h2>
      <div className="space-y-4">
        <Info
          titulo="DDoS (Distributed Denial of Service)"
          descripcion="Un ataque DDoS se produce cuando múltiples sistemas atacan a un solo objetivo, generalmente un servidor web, saturándolo con un volumen abrumador de tráfico. Esto hace que los recursos del servidor se agoten, impidiendo que usuarios legítimos accedan a los servicios o información en el sitio web."
        />
        <Info
          titulo="Ordenador Zombie"
          descripcion="Un ordenador zombie es un dispositivo que ha sido infectado por malware y está controlado de forma remota sin el conocimiento de su dueño. Estos ordenadores son utilizados en redes más grandes conocidas como botnets para realizar ataques masivos, como DDoS."
        />
        <Info
          titulo="Ciberdelincuente"
          descripcion="Un ciberdelincuente es una persona que utiliza técnicas de hacking y otros medios ilegales para cometer delitos en línea. Estos delitos pueden incluir el robo de datos, el fraude, la distribución de malware, entre otros."
        />
        <Info
          titulo="Botmaster"
          descripcion="Es la persona o entidad que controla una botnet. Los botmasters pueden utilizar redes de ordenadores zombies para llevar a cabo ataques masivos, como DDoS, envíos de spam, robo de datos o minería de criptomonedas."
        />
        <Info
          titulo="Botnet"
          descripcion="Una botnet es una red de dispositivos (computadoras, servidores, dispositivos IoT) que han sido infectados con malware y son controlados de manera remota por un botmaster. Estas redes son utilizadas para ataques masivos y pueden involucrar millones de dispositivos."
        />
        <Info
          titulo="Ransomware"
          descripcion="El ransomware es un tipo de malware que cifra los archivos de una víctima y exige un pago (usualmente en criptomonedas) para restaurar el acceso a los datos secuestrados. Las víctimas reciben un mensaje pidiendo un rescate (ransom). Ejemplos populares de ransomware incluyen WannaCry y Petya."
        />
        <Info
          titulo="Disclaimer"
          descripcion="En el contexto de ciberseguridad, un disclaimer es una declaración de advertencia que informa a los usuarios sobre los riesgos potenciales relacionados con el uso de ciertos servicios, como el acceso a sitios web inseguros o el uso de software malicioso. Generalmente, no es un ataque, sino una notificación legal."
        />
        <Info
          titulo="The Morris Worm"
          descripcion="Fue uno de los primeros gusanos informáticos en Internet, creado por Robert Tappan Morris en 1988. Se propagó rápidamente a través de vulnerabilidades en Unix, afectando aproximadamente el 10% de los servidores conectados a Internet en ese momento. Aunque no era malicioso, causó grandes interrupciones."
        />
        <Info
          titulo="Ciberwarfare (Ciber-guerra)"
          descripcion="La ciber-guerra es el uso de ataques cibernéticos por parte de un estado o nación contra otro con el objetivo de causar daño. Estos ataques pueden incluir sabotaje de infraestructura crítica, robo de información clasificada o la desactivación de redes de comunicación."
        />
        <Info
          titulo="Stuxnet"
          descripcion="Stuxnet es un gusano informático altamente sofisticado descubierto en 2010 que fue diseñado para sabotear sistemas industriales específicos, particularmente los de la planta nuclear de Natanz en Irán. Se cree que fue desarrollado por Estados Unidos e Israel para detener el programa nuclear iraní."
        />
        <Info
          titulo="Moonlight Maze"
          descripcion="Es el nombre de una serie de ciberataques que ocurrieron en la década de 1990, dirigidos principalmente contra agencias gubernamentales y militares estadounidenses. Los ataques fueron diseñados para robar información confidencial y se cree que estuvieron relacionados con actores rusos."
        />
        <Info
          titulo="Operation Aurora"
          descripcion="Fue una serie de ciberataques descubiertos en 2010 que se dirigieron contra importantes empresas tecnológicas y organizaciones gubernamentales, incluyendo Google. Los atacantes explotaron vulnerabilidades en Internet Explorer para acceder a sistemas internos y robar propiedad intelectual."
        />
        <Info
          titulo="Freeze it into submission"
          descripcion="El ataque cibernético conocido como “Freeze it into submission” es parte de una serie de ciberataques masivos que ocurrieron en 2023. Este ataque específico explotó una vulnerabilidad en el software de transferencia de archivos MOVEit, afectando a más de 62 millones de personas1. Entre las víctimas se encuentran grandes empresas como Shell y British Airways, así como el Departamento de Energía de Estados Unidos."
        />
        <Info
          titulo="WannaCry"
          descripcion="WannaCry fue un ataque de ransomware masivo en 2017 que afectó a cientos de miles de computadoras en todo el mundo. Aprovechaba una vulnerabilidad en el sistema operativo Windows y, una vez dentro, cifraba los archivos y pedía un rescate en Bitcoin para restaurar el acceso."
        />
        <Info
          titulo="Petya"
          descripcion="Petya es otro tipo de ransomware que no solo cifra archivos, sino que también sobrescribe el sector de arranque maestro (MBR) del disco duro, lo que impide que el sistema operativo arranque correctamente. Al igual que WannaCry, Petya se extendió rápidamente y exigía rescates en criptomonedas."
        />
        <Info
          titulo="Equifax (ataque)"
          descripcion="En 2017, la empresa de informes de crédito Equifax sufrió una de las mayores violaciones de datos de la historia, afectando a aproximadamente 147 millones de personas. Los atacantes explotaron una vulnerabilidad en el software Apache Struts para acceder a datos sensibles, incluyendo números de seguridad social, fechas de nacimiento y direcciones."
        />
        <Info
          titulo="Cam4 (ataque)"
          descripcion="En 2020, se descubrió una violación masiva de datos de Cam4, un sitio de transmisión en vivo para adultos. Se expusieron más de 10 mil millones de registros, incluyendo información personal como nombres, correos electrónicos, direcciones IP, y transacciones financieras. La brecha se debió a una base de datos Elasticsearch que no estaba debidamente asegurada.."
        />
      </div>
    </div>
  );
};

const Info = ({ titulo, descripcion }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">
      <img src={c} alt={titulo} className="rounded-lg" width={30} height={30} />
    </div>
    <div>
      <h3 className="text-lg font-bold">{titulo}</h3>
      <p className="text-gray-600">{descripcion}</p>
    </div>
  </div>
);


export default Seccion1;
