import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import userImage from './images/user.jpg';
import a from './images/1.jpg';
import b from './images/2.jpg';
import d from './images/4.jpg';
import e from './images/5.jpg';
import Seccion1 from './Seccion1';
import Seccion2 from './Seccion2';
import Seccion3 from './Seccion3';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-white">
        {/* Menú */}
        <header className="flex justify-between items-center p-5 bg-white shadow-md">
          <h1 className="font-bold text-lg">Alumno: Ramírez Martínez Natalia</h1>
          <div className="flex items-center space-x-2">
            <nav className="space-x-4">
              <Link to="/seccion1" className="text-gray-600 hover:text-pink-300">Sección 1</Link>
              <Link to="/seccion2" className="text-gray-600 hover:text-pink-300">Sección 2</Link>
              <Link to="/seccion3" className="text-gray-600 hover:text-pink-300">Sección 3</Link>
            </nav>
            <Link to="/" className="px-4 py-2 bg-pink-300 text-white rounded-lg hover:bg-pink-500">Inicio</Link>
            <img src={userImage} alt="User" className="rounded-full w-10 h-10" />
          </div>
        </header>

        {/* Configuración de las rutas */}
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/seccion1" element={<Seccion1 />} />
          <Route path="/seccion2" element={<Seccion2 />} />
          <Route path="/seccion3" element={<Seccion3 />} />
        </Routes>
      </div>
    </Router>
  );
};

const MainPage = () => {
  return (
    <main className="max-w-6xl mx-auto p-8 flex flex-col items-center">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        {/* Imagen */}
        <div className="flex justify-center">
          <img
            src={a}
            alt="Seguridad de la información"
            className="rounded-lg shadow-lg w-full max-w-md"
          />
        </div>
        {/* Texto principal */}
        <div className="flex flex-col justify-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">SEGURIDAD DE LA INFORMACIÓN</h2>
          <p className="text-gray-600 mb-4">
          La seguridad de la información es la práctica de proteger los datos y los sistemas de información contra amenazas como el acceso no autorizado, la divulgación, la alteración o la destrucción de información sensible. Su objetivo principal es garantizar la confidencialidad, integridad y disponibilidad de la información.
          La seguridad de la información es crucial para proteger la privacidad y la integridad de los datos, así como para mantener la confianza en los sistemas digitales.
          </p>
          <Link to="/"><button className="bg-pink-300 text-white px-4 py-2 rounded-lg w-max hover:bg-pink-500">
            Inicio
          </button></Link>
        </div>
      </section>

      {/* Imágenes adicionales */}
      <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
        <Link to="/seccion1"><img src={b} alt="Sección 1" className="rounded-md" /></Link>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
        <Link to="/seccion2"><img src={d} alt="Sección 2" className="rounded-md" /></Link>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow-md">
        <Link to="/seccion3"><img src={e} alt="Sección 3" className="rounded-md" /></Link>
        </div>
      </section>
    </main>
  );
};

export default App;


