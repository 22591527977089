import React from 'react';
import c from './images/7.jpg';

const Seccion3 = () => {
  return (
    <div className="bg-white p-5 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Principios de Seguridad de la Información (Sección 3)</h2>
      <div className="space-y-4">
        <Info
          titulo="Accesibilidad"
          descripcion="Se refiere a la capacidad de los usuarios autorizados de acceder a la información o recursos cuando los necesiten. La accesibilidad garantiza que los datos estén disponibles para quienes tienen los permisos adecuados, en el momento oportuno, sin barreras innecesarias."
        />
        <Info
          titulo="Confidencialidad"
          descripcion="Este principio asegura que la información solo sea accesible para las personas autorizadas y que no sea divulgada a entidades o individuos no autorizados. Esto se logra mediante técnicas como el cifrado, políticas de control de acceso y el manejo adecuado de contraseñas."
        />
        <Info
          titulo="Disponibilidad"
          descripcion="Se refiere a que los sistemas y la información estén accesibles y operativos siempre que sean necesarios. Garantiza que la información no esté bloqueada o inaccesible debido a fallos técnicos, ciberataques o desastres. Las copias de seguridad, redundancias y planes de recuperación ante desastres son esenciales para mantener la disponibilidad."
        />
        <Info
          titulo="Autenticación"
          descripcion="Este principio se encarga de verificar la identidad de los usuarios o sistemas que intentan acceder a la información. Asegura que solo las personas autorizadas, tras demostrar su identidad mediante contraseñas, tokens o biometría, puedan acceder a los datos sensibles."
        />
        <Info
          titulo="Integridad"
          descripcion="Se refiere a la protección de la exactitud y consistencia de la información durante su ciclo de vida. Garantiza que los datos no sean alterados o modificados sin autorización, ya sea por error o de forma malintencionada. Los mecanismos como las firmas digitales y los controles de hash ayudan a asegurar la integridad de los datos."
        />
        <Info
          titulo="Control de Acceso"
          descripcion="El control de acceso define y aplica reglas sobre quién puede ver o usar recursos dentro de un entorno de TI. Este principio limita el acceso a la información solo a usuarios autorizados, según roles y permisos específicos. Se implementa mediante políticas de seguridad, listas de control de acceso (ACLs) y sistemas de autenticación y autorización."
        />
      </div>
    </div>
  );
};

const Info = ({ titulo, descripcion }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">
      <img src={c} alt={titulo} className="rounded-lg" width={30} height={30} />
    </div>
    <div>
      <h3 className="text-lg font-bold">{titulo}</h3>
      <p className="text-gray-600">{descripcion}</p>
    </div>
  </div>
);


export default Seccion3;
